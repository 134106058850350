import React from 'react';
import AVA from "../Assets/Ava.jpg";
import TypingEffect from 'react-typing-effect';
import person2 from "../Assets/19.jpg";
import person3 from "../Assets/86.jpg";
import male from "../Assets/62.jpg";
import male2 from "../Assets/68.jpg";
import male3 from "../Assets/78.jpg";
import Star from "../Assets/star.svg"

const Hero = () => {
  const phrases = [
    "Driving Leads",
    "Increasing Sales",
    "Customer Retention",
    "Brand Awareness",
    "Enhancing Credibility"
  ];
  return (
    <div className="relative h-full lg:px-36">
      {/* <div
        className="absolute inset-0 bg-cover bg-center opacity-75 w-full"
        style={{
          backgroundImage: `url(${herobg})`,
        }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-50"></div> */}

      {/* Content */}
      <div className="relative z-10 p-8 lg:p-20 text-white">
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-col lg:flex-row gap-4 mb-5'>
            <div className='flex'>
              <img src={male} className='w-10 -mr-3 rounded-full' alt='person'/>
              <img src={person2} className='w-10 -mr-3 rounded-full' alt='person'/>
              <img src={male2} className='w-10 -mr-3 rounded-full' alt='person'/>
              <img src={person3} className='w-10 -mr-3 rounded-full' alt='person'/>
              <img src={male3} className='w-10 -mr-3 rounded-full' alt='person'/>
            </div>
            <div className='flex flex-col gap-1'>
            <div className='flex'>
              <img src={Star} className='w-4' alt='Star'/>
              <img src={Star} className='w-4' alt='Star'/>
              <img src={Star} className='w-4' alt='Star'/>
              <img src={Star} className='w-4' alt='Star'/>
              <img src={Star} className='w-4' alt='Star'/>
            </div>
            <div>loved by founders worldwide</div>
            </div>
          </div>
          <h1 className="text-4xl mb-12 md:text-5xl lg:text-6xl text-center font-bold">
            Website Design For<br />
            <TypingEffect
              text={phrases}
              speed={100}
              eraseSpeed={50}
              eraseDelay={1000}
              typingDelay={500}
              cursor={"|"}
            />
          </h1>
          <p className="text-xl mb-12 text-gray-500 text-center lg:px-20">Supercharge your online presence with expert Web solutions. </p>
          <div className="flex mb-4 items-center gap-2 bg-white text-black text-xl font-bold rounded-xl shadow-lg shadow-zinc-700 hover:shadow-lg hover:shadow-zinc-500 p-2 lg:p-4">
            <img src={AVA} alt='ava' className='w-14 rounded-full p-1 pointer-events-none' />
            <a href='https://calendly.com/designforgeekmain/30min' className='text-xl font-bold'>Book a Call with Ava</a>
          </div>
          <p className='flex text-gray-500'>"15+ calls booked this week"</p>
          <p className='flex italic text-gray-500'>No obligation. No subscription Required</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
