import React from "react";

const Book = () => {
    return(
        <div className="px-6 lg:px-32 mb-10 lg:mb-24">
            <div className="py-8 lg:py-20 flex flex-col justify-center items-center text-white gap-4">
            <h1 className="text-4xl md:text-5xl lg:text-7xl text-center font-bold">Still Thinking ??</h1>
            <h1 className="text-4xl mb-6 md:text-5xl lg:text-7xl text-center font-bold">Let's clear all your doubts</h1>
            <div className="transition ease-in-out delay-150 flex mb-4 items-center gap-2 bg-white text-black text-xl font-bold rounded-xl hover:-translate-y-1 hover:scale-110 shadow-lg shadow-zinc-700 hover:shadow-lg hover:shadow-zinc-500 px-6 lg:px-20 py-6">
            
            <a href='https://calendly.com/designforgeekmain/30min' className='text-xl font-bold'>Book a Call Now</a>
          </div>
          <p className='flex text-gray-500 text-center text-sm'>Fast decision-makers always reach ahead in life</p>
          </div>
        </div>
    )
}
export default Book