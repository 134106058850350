import React, { useState } from 'react';
import Logo from "../Assets/designforgeek logo 1.png";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="bg-black pt-8 pb-4 lg:px-36 py-4 flex items-center md:justify-between justify-center">
            {/* Logo and Menu items */}
            
            <div className="flex items-center">
                <img src={Logo} alt="Logo" className="h-12 mr-2" />
            </div>

            {/* Menu items */}
            <div className="hidden lg:flex items-center justify-center">
                <ul className="flex justify-center space-x-4">
                    <li>
                        <a href="#how-it-works" className="text-white hover:text-gray-300">How it works</a>
                    </li>
                    <li>
                        <a href="#recent-work" className="text-white hover:text-gray-300">Recent works</a>
                    </li>
                    <li>
                        <a href="#pricing" className="text-white hover:text-gray-300">Pricing</a>
                    </li>
                    <li>
                        <a href="#testimonials" className="text-white hover:text-gray-300">Testimonials</a>
                    </li>
                    <li>
                        <a href="#faq" className="text-white hover:text-gray-300">FAQ</a>
                    </li>
                </ul>
            </div>


            {/* Button on the right */}
            <div className="hidden lg:flex">
                {/* <button className="border border-white flex items-center justify-center gap-2 text-white font-bold py-2 px-4 rounded-full">
                    <img src={person} className='rounded-full w-6' alt='person'/>
                    <p>Book a call</p>
                </button> */}
                <a href="https://calendly.com/designforgeekmain/30min" class="hidden lg:flex items-center bg-purple text-white border border-gray-700 p-2 rounded-xl hover:shadow-md hover:shadow-zinc-700">Press<span class="font-bold px-1.5 bg-slate-600 rounded-lg m-1">&nbsp;B&nbsp;</span>to book a call</a>
            </div>
        </nav>
    );
};

export default Navbar;
