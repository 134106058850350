import React from "react";
import Twitter from "../Assets/twitter.png" 
import Facebook from "../Assets/facebook.png" 
import Instagram from "../Assets/instagram.png" 
import Linkedin from "../Assets/linkedin.png" 
import Heart from "../Assets/love.png"
import USA from "../Assets/usa.png"

const Footer =() => {
    return(
        <div className="px-8 lg:px-32">
            <hr className="pb-4"></hr>
            <div className="flex flex-col pb-4 lg:flex-row items-center justify-center">
                <p className="text-gray-500 text-center">Design for Geek. All right reserved. 2024<br/><br/>
                Made with <img src={Heart} alt="heart"  className="inline-block"/> from <img src={USA} alt="usa"  className="inline-block"/> </p>

                {/* <div className="flex gap-4">
                    <a href="#"><img src={Twitter} alt="twitter" className="w-6"/></a>
                    <a href="#"><img src={Facebook} alt="Facebook" className="w-6"/></a>
                    <a href="#"><img src={Instagram} alt="Instagram" className="w-6"/></a>
                    <a href="#"><img src={Linkedin} alt="Linkedin" className="w-6"/></a>
                </div> */}
            </div>
        </div>
    )
}

export default Footer;