import React from "react";
import Logo1 from "../Assets/Logo/1.png";
import Logo2 from "../Assets/Logo/10.png";
import Logo3 from "../Assets/Logo/11.png";
import Logo4 from "../Assets/Logo/12.png";
import Logo5 from "../Assets/Logo/13.png";
import Logo6 from "../Assets/Logo/14.png";
import Logo7 from "../Assets/Logo/15.png";
import Logo8 from "../Assets/Logo/16.png";
import Logo9 from "../Assets/Logo/17.png";
import Logo10 from "../Assets/Logo/18.png";
import Logo11 from "../Assets/Logo/19.png";
import Logo12 from "../Assets/Logo/20.png";
import Logo13 from "../Assets/Logo/21.png";
import Logo14 from "../Assets/Logo/2.png";
import Logo15 from "../Assets/Logo/3.png";
import Logo16 from "../Assets/Logo/4.png";
import Logo17 from "../Assets/Logo/5.png";
import Logo18 from "../Assets/Logo/6.png";
import Logo19 from "../Assets/Logo/7.png";
import Logo20 from "../Assets/Logo/8.png";
import Logo21 from "../Assets/Logo/9.png";
import Marquee from "react-fast-marquee";

const Partner = () => {
  return (
    <>
      <section className="py-6 lg:py-14">
        <Marquee className="">
          <div className="flex justify-center items-center">
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo1} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo2} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo3} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo4} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo5} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo6} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo7} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] white-logo" src={Logo8} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo9} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo10} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo11} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo12} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo13} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] white-logo" src={Logo14} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo15} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo16} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo17} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo18} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo19} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo20} />
            </a>
            <a href="/">
              <img className="md:max-w-[250px] max-w-[150px] " src={Logo21} />
            </a>
          </div>
        </Marquee>
      </section>
    </>
  );
};

export default Partner;
