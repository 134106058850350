import React from "react";
import thumbsup from "../Assets/thumbs-up.svg"

const Pricing = () => {
  return (
    <div className="m-8 rounded-3xl bg-gradient-to-b from-zinc-950 to-black flex flex-col items-center justify-center gap-4 text-white lg:p-20" id="pricing">
      <p className="text-xl text-gray-300 pt-3 lg:pt-0">Pricing</p>
      <h1 className="text-4xl md:text-5xl lg:text-6xl text-center font-bold">Simple & transparent pricing</h1>
      <h1 className="text-4xl md:text-5xl lg:text-6xl text-center font-bold flex items-center justify-center">
        to help you grow
        <img src={thumbsup} alt="thumbs up" className="w-20" />
      </h1>
      <div className="flex flex-col pt-10 lg:pt-20 gap-4">
        <div className="flex flex-col w-full bg-zinc-900 rounded-xl p-8 gap-6">
          <h1 className="font-bold text-3xl text-center">One Simple Plan</h1>
          <p className="text-gray-500 text-center">Get started with your website development with us.</p>
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-yellow-400 text-center"> <span className="text-white line-through font-medium">$1999</span> $999</h1>
          <hr className="text-gray-500"></hr>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex flex-col gap-4">
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Modern & Responsive Design </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> 2x Revisions </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Dedicated Project Manager </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> AI Chatbot Integration </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Custom illustrations / Graphics </p>

            </div>
            <div className="flex flex-col gap-4">
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> One-Week Express Delivery </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Daily Progress Updates </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> 15-Day Support for Minor Changes </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Free Domain for One Year </p>
              <p className="flex gap-2 text-xl items-center"><img src={thumbsup} alt="right mark" className="w-6" /> Hosting Support and Guidance </p>
            </div>
          </div>
          <a href="https://calendly.com/designforgeekmain/30min" className="border border-white rounded-full w-full p-5 mt-3 font-bold text-center text-2xl">Get started today</a>
        </div>
      </div>
    </div>
  )
}

export default Pricing;