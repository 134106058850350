import React from "react";

const Stickybar = () => {
    return (
        <div class="sticky top-0 z-50 bg-white lg:mt-0 flex flex-row items-center space-x-2 justify-center cursor-pointer text-center p-2">
            <div class="font-semibold">Launch Your Dream Site in 5 Days! </div>
            <a href="https://calendly.com/designforgeekmain/30min" className="underline font-bold">Let's Talk</a>
        </div>
    )
}

export default Stickybar;