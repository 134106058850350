import React from "react";
import Figma from "../Assets/figma.webp"
import Next from "../Assets/nextjs.webp"
import Vercel from "../Assets/vercel.webp"
import Tailwind from "../Assets/tailwind.webp"
import Framer from "../Assets/framer-motion.webp"
import Reactjs from "../Assets/react.png"
import Nodejs from "../Assets/nodejs.png"
import MongoDB from "../Assets/mongodb.png"
import Firebase from "../Assets/firebase.png"
import Bootstrap from "../Assets/bootstrap.png"
import Solidity from "../Assets/solidity.svg"


const Tools = () => {
    return (
        <div class="pt-10 lg:pt-[6rem] flex flex-col items-center justify-center space-y-6">
            <div class="flex flex-row items-center justify-center space-x-1">
                <span class=" text-white/50  text-xs lg:text-base">TOOLS</span>
            </div>
            <div class="grid grid-cols-4  gap-5 lg:flex lg:flex-row items-center lg:space-x-10">
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo" src={Figma} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Next} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Reactjs} />
                <img loading="eager" width="100" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Nodejs} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo" src={Bootstrap} />
                <img loading="eager" width="60" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Tailwind} />
                <img loading="eager" width="100" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={MongoDB} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo" src={Firebase} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo" src={Vercel} />
                <img loading="eager" width="40" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Framer} />
                <img loading="eager" width="60" height="100" decoding="async" alt="logo" data-nimg="1" class="white-logo " src={Solidity} />
            </div>
        </div>
    )
}
export default Tools