import React, { useState } from "react";
import Wordpress1 from "../Assets/Clients/longmoonpro.png"
import Wordpress2 from "../Assets/Clients/next-assignment.png"
import Wordpress3 from "../Assets/Clients/ghumaisa.png"
import Ecommerce1 from "../Assets/Clients/mobicationhub.png"
import Ecommerce2 from "../Assets/Clients/modelmentor.png"
import Ecommerce3 from "../Assets/Clients/furnmill.png"
import AI1 from "../Assets/Clients/byteai.png"
import AI2 from "../Assets/Clients/teq-network.png"
import AI3 from "../Assets/Clients/Nosana.png"
import AI4 from "../Assets/Clients/nov ai.png"
import AI5 from "../Assets/Clients/Gen ai.png"
import AI6 from "../Assets/Clients/GTC AI.png"
import Blockchain1 from "../Assets/Clients/RWA Block.png"
import Blockchain2 from "../Assets/Projects/Giga.png"
import Blockchain3 from "../Assets/Clients/bit swap.png"
import Blockchain4 from "../Assets/Clients/Chain Gpu.png"
import Blockchain5 from "../Assets/Clients/miner gate.png"
import Blockchain6 from "../Assets/Projects/Bullish.png"
import Project1 from "../Assets/Clients/pyramid ai.png"
import Project2 from "../Assets/Clients/klay chain.png"
import Project3 from "../Assets/Clients/inferix.png"
import Project4 from "../Assets/Clients/brc.png"
import Project5 from "../Assets/Clients/on chain.png"
import Project6 from "../Assets/Clients/live.png"

const Project = () => {
    const [activeTab, setActiveTab] = useState(3);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    return (
        <div className="p-8 lg:px-36 lg:py-20 flex flex-col items-center justify-center text-white" id="recent-work">
            <h1 className="text-4xl md:text-5xl lg:text-6xl text-center font-bold">Recent Works</h1>
            <div className="flex gap-2 lg:flex-row flex-col justify-center mt-8">
                <button
                    className={`px-4 py-1 text-base font-semibold uppercase rounded-full ${activeTab === 3 ? "bg-white text-black" : "bg-transparent border border-white text-white"
                        }`}
                    onClick={() => handleTabClick(3)}
                >
                    AI Sites
                </button>
                <button
                    className={`px-4 py-1 text-base font-semibold uppercase rounded-full ${activeTab === 1 ? "bg-white text-black" : "bg-transparent border border-white text-white"
                        }`}
                    onClick={() => handleTabClick(1)}
                >
                    Wordpress Site
                </button>
                <button
                    className={`px-4 py-1 text-base font-semibold uppercase rounded-full ${activeTab === 2 ? "bg-white text-black" : "bg-transparent border border-white text-white"
                        }`}
                    onClick={() => handleTabClick(2)}
                >
                    E-Commerce
                </button>
                <button
                    className={`px-4 py-1 text-base font-semibold uppercase rounded-full ${activeTab === 4 ? "bg-white text-black" : "bg-transparent border border-white text-white"
                        }`}
                    onClick={() => handleTabClick(4)}
                >
                    Blockchain Site
                </button>
                <button
                    className={`px-4 py-1 text-base font-semibold uppercase rounded-full ${activeTab === 5 ? "bg-white text-black" : "bg-transparent border border-white text-white"
                        }`}
                    onClick={() => handleTabClick(5)}
                >
                    Landing Page
                </button>
            </div>
            <div className="mt-8">
                {activeTab === 1 &&
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://longmoonpro.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Wordpress1} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Longmoon Pro</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.next-assignment.in/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Wordpress2} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Next Assignment</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.ghumaisa.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Wordpress3} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Ghumaisa</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                    </div>
                }
                {activeTab === 2 &&
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://mobicationhub.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Ecommerce1} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Mobication Hub</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://modelmentor.in/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Ecommerce2} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Model Mentor</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://furnmill.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Ecommerce3} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Furn Mill</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                    </div>
                }
                {activeTab === 3 &&
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.byteai.tech/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI1} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Byte AI</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://teq-network.vercel.app/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI2} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Teq Network</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://nosana.vercel.app/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI3} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Nosana</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.novai.online/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI4} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Nov AI</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.genaio.me/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI5} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">GEN AI</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.gtcai.net/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={AI6} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">GTC AI</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                    </div>
                }
                {activeTab === 4 &&
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.rwablock.net/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain1} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Rwa Block</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.bitswsp.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain3} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Bit Swap</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.chaingpu.net/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain4} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">ChainGPU</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.minergatetoken.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain5} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Miner Gate</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.gigaprotocol.net/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain2} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Giga Protocol</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.bullishtoken.shop/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Blockchain6} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Bullish Ecosystem</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                    </div>
                }
                {activeTab === 5 &&
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.pyramidai.online/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project1} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Pyramid AI</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.klaychain.tech/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project2} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Klay Chain</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://inferix.vercel.app/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project3} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Inferix</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4">
                            <a href="https://www.brcautobot.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project4} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">BrcAutoBot</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.onchaintoolkit.com/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project5} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Onchain Toolkit</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                            <a href="https://www.liveing.live/">
                                <div className="bg-zinc-900 rounded-xl p-2 flex flex-col">
                                    <img src={Project6} alt="project 1" className="rounded-xl w-full" />
                                    <p className="font-bold">Live</p>
                                    {/* <p className="text-gray-500">web design</p> */}
                                </div>
                            </a>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
};

export default Project;
