import React, { useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import FAQ from './Components/Faq';
import Footer from './Components/Footer';
import Testimonial from './Components/Testimonial';
import Pricing from './Components/Pricing';
import Service from './Components/Services';
import Slogan from './Components/Slogan';
import Howitworks from './Components/Howitworks';
import Project from './Components/RecentProjects';
import Client from './Components/Clients';
import Partner from './Components/Partners';
import Book from './Components/Book';
import Stickybar from './Components/Stickybar';
import Tools from './Components/Tools';


function App() {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'b' || event.key === 'B' ) {
        window.location.href = 'https://calendly.com/designforgeekmain/30min';
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
    <div className="bg-black">
      <Stickybar/>
      <Navbar/>
      <Hero/>
      <Partner/>
      <Client/>
      <Howitworks/>
      <Slogan/>
      <Project/>
      <Service/>
      <Tools/>
      <Pricing/>
      <Testimonial/>
      <FAQ/>
      <Book/>
      <Footer/>
    </div>
  );
}

export default App;
