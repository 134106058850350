import React, { useState } from 'react';
import FAQIMG from "../Assets/faq-memoji.svg"
import Bolt from "../Assets/bolt-bg.svg"

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    const faqs = [
        {
            question: 'What is a development sprint and how does it work?',
            answer: 'At Design for Geek, a development sprint is a turbocharged approach where we intensely collaborate over a short period to rapidly propel your project forward. We meticulously plan objectives, execute tasks with precision, and maintain open communication throughout, ensuring your vision is not just realized but surpassed.',
        },
        {
            question: 'How long does it take?',
            answer: 'At Design for Geek, we prioritize swift and efficient website delivery. While timelines vary based on complexity, most landing pages and basic websites are completed within 2-3 days, while custom projects typically take just one week.',
        },
        {
            question: 'Is it compatible with mobile?',
            answer: 'Absolutely! Mobile responsiveness is at the core of our web development ethos. We understand the significance of ensuring your website looks and functions flawlessly across all devices, especially on mobile.',
        },
        {
            question: 'What is your process for gathering requirements and understanding our business needs before starting the project?',
            answer: 'We start by listening. Our process involves in-depth consultations to understand your business needs and goals. Through collaboration and research, we tailor our solutions to ensure they align perfectly with your objectives, driving your success.',
        },
        {
            question: 'What sets your agency apart from others in the industry?',
            answer: 'Our commitment to innovation and client success sets us apart. We combine cutting-edge technology with personalized service, ensuring that every project is tailored to exceed expectations. With a focus on collaboration and continuous improvement, we are dedicated to delivering results that truly make a difference for our clients.',
        },
        {
            question: 'What is your process for handling website updates and changes after the initial development?',
            answer: 'To ensure your website remains in peak condition, we include one month of complimentary maintenance and updates in our initial pricing. After this period, we offer ongoing maintenance services for $200 per month, handling all necessary updates and enhancements. Additionally, we provide you with access to the source code, empowering you to make changes independently if desired.',
        },
        {
            question: 'What platforms or technologies do you specialize in?',
            answer: 'We specialize in a variety of platforms and technologies, including but not limited to WordPress, Shopify, Magento, and custom solutions tailored to your unique needs. Our expertise ensures we can deliver the best solution for your project, no matter the requirements.',
        },
    ];

    return (
        <div className='flex flex-col gap-4 items-center justify-center p-8 lg:p-20 lg:px-36' id='faq'>
            <p className='text-gray-300'>FAQ</p>
            <h1 className="text-4xl md:text-5xl lg:text-6xl text-center text-white font-bold">Questions? Answers. </h1>
            {/* <div className='lg:p-20 w-full'>
                {faqs.map((faq, index) => (
                    <div key={index} className="p-4 rounded-lg mb-4">
                        <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleAccordion(index)}
                        >
                            <h2 className="text-2xl text-white font-semibold">{faq.question}</h2>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-6 w-6 transition-transform transform ${openIndex === index ? 'rotate-180' : 'rotate-0'
                                    }`}
                                viewBox="0 0 20 20"
                                fill="#fff"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 3.829a.75.75 0 01.75.75v8.586l2.536-2.536a.75.75 0 011.061 1.061l-3.535 3.536a.75.75 0 01-1.06 0L5.653 11.89a.75.75 0 111.06-1.06L9.25 13.165V4.579a.75.75 0 01.75-.75z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        {openIndex === index && (
                            <div className="mt-4">
                                <p className="text-gray-500 text-xl">{faq.answer}</p>
                            </div>
                        )}
                        <hr className='mt-10 text-gray-600'></hr>
                    </div>
                ))}
            </div> */}
            <div class="flex flex-col lg:flex-row items-start lg:space-x-20 lg:pt-10 space-y-10 lg:space-y-0 p-4 rounded-xl bg-zinc-900">
                <div class="grid md:w-2/3 divide-y divide-white/10">
                    {faqs.map((faq, index) => (
                        <div class="py-5" key={index}>
                            <details class="group w-full">
                                <summary class="flex cursor-pointer list-none items-center justify-between  space-x-5">
                                    <span class="lg:text-2xl text-white font-medium ">
                                        {faq.question}
                                    </span>
                                    <span class="transition duration-300 group-open:rotate-45">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="text-white text-xl w-8 h-8 p-1 bg-white/10 rounded-full">
                                            <path fill-rule="evenodd" d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z" clip-rule="evenodd">
                                            </path>
                                        </svg>
                                    </span>
                                </summary><div class="pt-3">
                                    <span class="group-open:animate-fadeIn text-[#ffffff99] text-start leading-7 w-1/2 text-lg">
                                        {faq.answer}
                                    </span>
                                </div>
                            </details>
                        </div>
                    ))}
                </div>
                <div class="relative flex items-center justify-center lg:w-1/3">
                    <img alt="scribble" loading="eager" width="30" height="50" decoding="async" data-nimg="1" class="w-full" src={Bolt} />
                    <div class="p-5 md:p-10 absolute top-0 left-0 flex flex-col items-center justify-center text-center w-full h-full space-y-5">
                        <a href="#">
                            <img alt="scribble" loading="eager" width="30" height="50" decoding="async" data-nimg="1" class="w-[100px] xl:w-[150px] cursor-pointer transparent" src={FAQIMG} />
                        </a>
                        <div class="flex flex-col items-center justify-center">
                            <span class="lg:text-xl xl:text-2xl text-white font-medium ">
                                Have more questions?
                            </span>
                            <span class="lg:text-xl xl:text-2xl text-white font-medium ">
                                Book a FREE intro call.
                            </span>
                        </div>
                        <a href="https://calendly.com/designforgeekmain/30min" class="rounded-xl bg-white p-3.5 lg:p-4 px-10 lg:px-16 text-md lg:text-lg font-bold text-primary tracking-tight cta-btn cursor-pointer hover:scale-105 transition duration-300 ease-out">
                            Book a Call
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
