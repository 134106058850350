import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "../App.css"
import "swiper/swiper-bundle.css";
import thumbsup from "../Assets/thumbs-up.svg";
import person from "../Assets/11.jpg";
import person2 from "../Assets/19.jpg";
import person3 from "../Assets/86.jpg";
import male from "../Assets/62.jpg";
import male2 from "../Assets/68.jpg";
import male3 from "../Assets/78.jpg";
import videoFile1 from "../Assets/testimonial-video.mp4";
import videoFile2 from "../Assets/testimonial-video2.mp4";
import Instagram from "../Assets/instagram.png"
import Star from "../Assets/star.svg"

const testimonials = [
    {
        img: person,
        name: "Oliver Thompson",
        title: "Co-Founder At Gen AI",
        quote: "Design for Geek exceeded our expectations in every way. Their team developed a sleek, user-friendly website that has significantly boosted our online presence. They were attentive to our needs and provided excellent support throughout the entire process. We couldn’t be happier with the results!",
    },
    {
        img: male,
        name: "Harper Smith",
        title: "Founder At Miner Gate",
        quote: "Working with Design for Geek was a fantastic experience. Their expertise in web development is evident in the high-quality website they created for us. The project was completed on time, and their team was always available to address any concerns we had. Highly recommend their services!",
    },
    {
        img: male2,
        name: "Max Ramirez",
        title: "CTO At Giga Protocol",
        quote: "Choosing Design for Geek for our website development was one of the best decisions we made. Their team brought creativity and technical expertise to the table, delivering a website that’s both beautiful and functional. The seamless process and their dedication to our project made all the difference. Our new site has been a game-changer for our business.",
    },
    {
        img: male3,
        name: "Chloe Johnson",
        title: "CEO At Onchain Toolkit",
        quote: "Design for Geek provided outstanding service from start to finish. They took the time to understand our business needs and crafted a website that truly represents our brand. Their innovative approach and technical skills are top-notch. We’re thrilled with the final product and the ongoing support.",
    },
    {
        img: person2,
        name: "Ethan Brown",
        title: "Manager At Ghumaisa",
        quote: "I am extremely pleased with the website Design for Geek created for our company. Their team was professional, responsive, and skilled at turning our vision into reality. The website’s performance has been excellent, and we’ve received numerous compliments from our clients. Thank you for a job well done!",
    },
    {
        img: person3,
        name: "Mia Martinez",
        title: "Founder & CEO At ChainGPU",
        quote: "We approached Design for Geek with a complex project, and they delivered beyond our expectations. The website they built is not only visually stunning but also highly functional. Their attention to detail and commitment to excellence is unparalleled. Our online traffic has seen a remarkable increase since the launch.",
    },
];
const videos = [
    {
        reel: videoFile1,
        name: "Sujal Jethwani",
        link: "https://www.instagram.com/sujal.jethwani?igsh=MTFscHFqaGx5N3o3Mg==",
        followers: "1M+ Followers Base",
        company: "Founder, Growrex",
    },
    {
        reel: videoFile2,
        name: "Harshita Jain",
        link: "https://www.instagram.com/_harsshita.jain_",
        followers: "12K+ Followers",
        // company: "Founder, Growrex",
    },
];

const Testimonial = () => {
    return (
        <div className="py-8 p flex flex-col items-center justify-center gap-4 text-white lg:px-36 lg:py-20" id="testimonials">
            <p className="text-xl text-gray-300">Wall of love</p>
            <h1 className="text-4xl md:text-5xl pl-2 lg:text-6xl text-center font-bold flex items-center justify-center">
                What our clients said <img src={thumbsup} alt="thumbs up" className="w-20" />
            </h1>
            {/* Slider for mobile devices */}
            <div className="block lg:hidden w-full pt-10 lg:pt-20">
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {videos.map((video, index) => (
                        <SwiperSlide key={index}>
                            {/* <div className="w-full mx-12 lg:hidden flex justify-center"> */}
                            <div className="border border-gray-500 rounded-xl mb-20 p-4 gap-6 flex flex-col mx-10">
                                <video className="" autoPlay loop controls>
                                    <source src={video.reel} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="flex flex-col items-start mt-2">
                                    <p className="flex gap-2 text-white font-bold text-xl"><a href="video.link" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram" className="w-6" /></a>{video.name}</p>
                                    <div className='flex gap-1'>
                                        <img src={Star} className='w-4' alt='Star' />
                                        <img src={Star} className='w-4' alt='Star' />
                                        <img src={Star} className='w-4' alt='Star' />
                                        <img src={Star} className='w-4' alt='Star' />
                                        <img src={Star} className='w-4' alt='Star' />
                                    </div>
                                    <p className="text-white font-bold text-xl">{video.followers}</p>
                                    <p className="text-white font-bold text-xl">{video.company}</p>
                                </div>
                            </div>
                            {/* </div> */}
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div className="border border-gray-500 rounded-xl p-4 gap-6 flex flex-col mx-10">
                                <div className="flex gap-4">
                                    <img src={testimonial.img} alt="person" className="border border-white w-6 h-6 rounded-full pointer-events-none" />
                                    <div className="flex flex-col justify-between">
                                        <p className="text-white font-bold text-xl">{testimonial.name}</p>
                                        <p className="text-gray-500">{testimonial.title}</p>
                                    </div>
                                </div>
                                        <div className='flex gap-1'>
                                            <img src={Star} className='w-4' alt='Star' />
                                            <img src={Star} className='w-4' alt='Star' />
                                            <img src={Star} className='w-4' alt='Star' />
                                            <img src={Star} className='w-4' alt='Star' />
                                            <img src={Star} className='w-4' alt='Star' />
                                        </div>
                                <p className="text-xl">{testimonial.quote}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* Original layout for desktop */}
            <div className="hidden lg:flex flex-row justify-evenly gap-5 pt-20">
                <div className="border border-gray-500 rounded-xl p-2 w-1/4">
                    <video className="w-full" autoPlay loop controls>
                        <source src={videoFile1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="flex flex-col items-start mt-2">
                        <p className="flex gap-2 text-white font-bold text-xl"><a href="https://www.instagram.com/sujal.jethwani?igsh=MTFscHFqaGx5N3o3Mg==" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram" className="w-6" /></a>Sujal Jethwani</p>
                        <div className='flex gap-1'>
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                        </div>
                        <p className="text-white font-bold text-xl">1M+ Followers Base</p>
                        <p className="text-white font-bold text-xl">Founder, Growrex</p>
                    </div>
                </div>
                <div className="border border-gray-500 rounded-xl p-2 w-1/4">
                    <video className="w-full" autoPlay loop controls>
                        <source src={videoFile2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="flex flex-col items-start mt-2">
                        <p className="flex gap-2 text-white font-bold text-xl"><a href="https://www.instagram.com/_harsshita.jain_" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram" className="w-6" /></a>Harshita Jain</p>
                        <div className='flex gap-1'>
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                            <img src={Star} className='w-4' alt='Star' />
                        </div>
                        <p className="text-white font-bold text-xl">12K+ Followers</p>
                        {/* <p className="text-white font-bold text-xl">Founder, Growrex</p> */}
                    </div>
                </div>
            </div>
            <div className="hidden lg:flex flex-col lg:flex-row gap-4 pt-10 lg:pt-20">
                <div className="flex flex-col w-full lg:w-1/3 gap-4">
                    {testimonials.slice(0, 2).map((testimonial, index) => (
                        <div key={index} className="border border-gray-500 rounded-xl p-4 gap-6 flex flex-col">
                            <div className="flex gap-4">
                                <img src={testimonial.img} alt="person" className="border border-white w-12 h-12 rounded-full pointer-events-none" />
                                <div className="flex flex-col justify-between">
                                    <p className="text-white font-bold text-xl">{testimonial.name}</p>
                                    <p className="text-gray-500">{testimonial.title}</p>
                                </div>
                            </div>
                            <div className='flex gap-1'>
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                            </div>
                            <p className="text-xl">{testimonial.quote}</p>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-full lg:w-1/3 gap-4">
                    {testimonials.slice(2, 4).map((testimonial, index) => (
                        <div key={index} className="border border-gray-500 rounded-xl p-4 gap-6 flex flex-col">
                            <div className="flex gap-4">
                                <img src={testimonial.img} alt="person" className="border border-white w-12 h-12 rounded-full pointer-events-none" />
                                <div className="flex flex-col justify-between">
                                    <p className="text-white font-bold text-xl">{testimonial.name}</p>
                                    <p className="text-gray-500">{testimonial.title}</p>
                                </div>
                            </div>
                            <div className='flex gap-1'>
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                            </div>
                            <p className="text-xl">{testimonial.quote}</p>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-full lg:w-1/3 gap-4">
                    {testimonials.slice(4, 6).map((testimonial, index) => (
                        <div key={index} className="border border-gray-500 rounded-xl p-4 gap-6 flex flex-col">
                            <div className="flex gap-4">
                                <img src={testimonial.img} alt="person" className="border border-white w-12 h-12 rounded-full pointer-events-none" />
                                <div className="flex flex-col justify-between">
                                    <p className="text-white font-bold text-xl">{testimonial.name}</p>
                                    <p className="text-gray-500">{testimonial.title}</p>
                                </div>
                            </div>
                            <div className='flex gap-1'>
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                                <img src={Star} className='w-4' alt='Star' />
                            </div>
                            <p className="text-xl">{testimonial.quote}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
};

export default Testimonial;
