import React from 'react';
import thumbsup from "../Assets/thumbs-up.svg"
import Scribble from "../Assets/services-scribble.svg"

const Service = () => {
    return (
        // <div className="p-8 lg:px-36 lg:py-20 flex flex-col text-white items-center justify-center">
        //     <p className="text-xl text-gray-300">Services</p>
        //     <h1 className="text-4xl md:text-5xl lg:text-6xl text-center font-bold">Websites, custom development... you name it.</h1>
        //     <div className="flex flex-wrap itmes-center justify-center pt-10 lg:pt-20 gap-4">
        //         <div className="bg-zinc-900 rounded-xl flex flex-col gap-4 p-4">
        //             <img src={thumbsup} alt="thumbsup" className='w-10'/>
        //             <h1 className="font-bold text-2xl">Shopify Specialization</h1>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Shopify-Centric Site </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Tailored Theme Development </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> One-Week Delivery </p>
        //         </div>
        //         <div className="bg-zinc-900 rounded-xl flex flex-col gap-4 p-4">
        //             <img src={thumbsup} alt="thumbsup" className='w-10'/>
        //             <h1 className="font-bold text-2xl">Wordpress Specialization</h1>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> WordPress-Centric Site </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Tailored Theme Development </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> One-Week Delivery </p>
        //         </div>
        //         <div className="bg-zinc-900 rounded-xl flex flex-col gap-4 p-4">
        //             <img src={thumbsup} alt="thumbsup" className='w-10'/>
        //             <h1 className="font-bold text-2xl">Custom Coded Website</h1>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Custom HTML/React Site </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Custom Sections & Features </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Fully Self-Coded Site </p>
        //         </div>
        //         <div className="bg-zinc-900 rounded-xl flex flex-col gap-4 p-4">
        //             <img src={thumbsup} alt="thumbsup" className='w-10'/>
        //             <h1 className="font-bold text-2xl">No-Code Website</h1>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Framer/Bubble.io No-Code Site </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Comprehensive Functionality </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Fast & SEO Optimised </p>
        //         </div>
        //         <div className="bg-zinc-900 rounded-xl flex flex-col gap-4 p-4">
        //             <img src={thumbsup} alt="thumbsup" className='w-10'/>
        //             <h1 className="font-bold text-2xl">Customized Website</h1>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Your Preferred Website </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Custom Crafted for Your Needs </p>
        //             <p className="flex gap-2 text-xl text-gray-500 items-center"><img src={thumbsup} alt="right mark" className="w-6"/> Developed on Your Preferred Technology </p>
        //         </div>
        //     </div>
        // </div>
        <div class="flex flex-col lg:flex-row items-center text-center justify-between pt-20 p-8 lg:px-36 lg:py-20 space-y-6 lg:space-y-0">
            <div class="flex flex-col">
                <span class="flex flex-col font-semibold text-2xl lg:items-start text-[#868686]">
                    <span>Logos</span>
                    <span>Icons</span>
                    <span>Branding</span>
                    <span>Copywriting</span>
                    <span>Pitch Decks</span>
                    <span>Brand Audit</span>
                    <span>Email Designs</span>
                    <span>Social Media Posts</span>
                </span>
            </div>
            <div class="">
                <h2 class="md:w-[400px] xl:w-[600px] text-4xl md:text-5xl text-center font-bold text-white">
                    You name it, we ship it
                    <span class="relative">&nbsp;Design for geeks
                        <img alt="logo" loading="eager" width="350" height="50" decoding="async" data-nimg="1" class="hidden md:flex absolute right-0 top-[-5px] xl:top-[-10px]" src={Scribble} />
                    </span>
                </h2>
            </div>
            <div class="flex flex-col">
                <span class="flex flex-col font-semibold text-2xl lg:items-end text-[#868686]">
                    <span>CMS</span>
                    <span>Landing Pages</span>
                    <span>Web Applications</span>
                    <span>Multipage Websites</span>
                    <span>Framer Development</span>
                    <span>Webflow Development</span>
                    <span>React Development</span>
                </span>
            </div>
        </div>
    )
}

export default Service;