import React from "react";
import Arrow from "../Assets/arrow.png"
import Plan from "../Assets/pick-a-plan.png"
import Request from "../Assets/Request-&-revise.png"
import Launch from "../Assets/Launch & grow.png"

const Howitworks = () => {
    return(
        <div className="m-8 rounded-3xl bg-gradient-to-b from-zinc-950 to-black flex flex-col items-center justify-center gap-4 text-white lg:py-20 lg:px-36" id="how-it-works">
          <p className="text-xl text-gray-300 pt-3 lg:pt-0">Easy as 1,2,3...</p>
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-center font-bold">How it works?</h1>
          <div className="flex flex-col pt-10 lg:pt-20 items-center lg:flex-row gap-10 lg:gap-2">
            <div className="flex flex-col items-center justify-center gap-4">
                <img src={Plan} alt="Pick a plan" />
                <h1 className="font-bold text-2xl">Pick Us</h1>
                <p className="text-gray-500 text-xl text-center">Just choose us. We start fast, no confusing costs or waiting. One single simple plan.</p>
            </div>
            <img src={Arrow} alt="arrow" className="rotate-90 lg:rotate-0 h-10"/>
            <div className="flex flex-col items-center justify-center gap-4">
                <img src={Request} alt="Pick a plan" />
                <h1 className="font-bold text-2xl">Request & revise</h1>
                <p className="text-gray-500 text-xl text-center">See your project take shape quickly. We begin as soon as you're ready.</p>
            </div>
            <img src={Arrow} alt="arrow" className="h-10 rotate-90 lg:rotate-0"/>
            <div className="flex flex-col items-center justify-center gap-4">
                <img src={Launch} alt="Pick a plan" />
                <h1 className="font-bold text-2xl">Launch & grow</h1>
                <p className="text-gray-500 text-xl text-center">Get your custom solution, finetuned as needed, all within your budget.</p>
            </div>
          </div>
        </div>
    )
}

export default Howitworks;