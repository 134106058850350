import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";

// Import images
import AI1 from "../Assets/Clients/Chain Gpu.png";
import AI2 from "../Assets/Clients/GTC AI.png";
import AI3 from "../Assets/Clients/Gen ai.png";
import AI4 from "../Assets/Clients/Nosana.png";
import AI5 from "../Assets/Clients/RWA Block.png";
import AI6 from "../Assets/Clients/bit swap.png";
import AI7 from "../Assets/Clients/brc.png";
import AI8 from "../Assets/Clients/byteai.png";
import AI9 from "../Assets/Clients/furnmill.png";
import AI10 from "../Assets/Clients/ghumaisa.png";
import AI11 from "../Assets/Clients/inferix.png";
import AI12 from "../Assets/Clients/klay chain.png";
import AI13 from "../Assets/Clients/live.png";
import AI14 from "../Assets/Clients/longmoonpro.png";
import AI15 from "../Assets/Clients/miner gate.png";
import AI16 from "../Assets/Clients/mobicationhub.png";
import AI17 from "../Assets/Clients/modelmentor.png";
import AI18 from "../Assets/Clients/next-assignment.png";
import AI19 from "../Assets/Clients/nov ai.png";
import AI20 from "../Assets/Clients/on chain.png";
import AI21 from "../Assets/Clients/pyramid ai.png";
import AI22 from "../Assets/Clients/teq-network.png";

const Client = () => {
  return (
    <div className="p-8 lg:p-20">
      <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={30}
      navigation
      pagination={{ clickable: true }}
      // Responsive breakpoints
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 4,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 5,
        },
      }}
      // Default slidesPerView
      slidesPerView={5}
    >
        <SwiperSlide>
          <img src={AI1} alt="byteai" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI2} alt="TeqNetwork" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI3} alt="Nosana" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI4} alt="NovAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI5} alt="GenAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI6} alt="GTCAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI7} alt="byteai" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI8} alt="TeqNetwork" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI9} alt="Nosana" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI10} alt="NovAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI11} alt="GenAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI12} alt="GTCAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI13} alt="byteai" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI14} alt="TeqNetwork" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI15} alt="Nosana" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI16} alt="NovAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI17} alt="GenAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI18} alt="GTCAI" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI19} alt="byteai" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI20} alt="TeqNetwork" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI21} alt="Nosana" className="h-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={AI22} alt="NovAI" className="h-[350px]" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Client;
